export enum Lang {
  SV = "sv",
  EN = "en",
}

export const Category = {
  Project: { sv: "Projekt", en: "Project" },
  Work: { sv: "Arbete", en: "Work" },
  University: { sv: "Universitet", en: "University" },
  Thesis: { sv: "Examensarbete", en: "Thesis" },
};
